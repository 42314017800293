$( document ).ready( function() {
	function faces(){
		var btn = $('.js-faces a');
		btn.on('click',function (e) {
			e.preventDefault();
			btn.removeClass('active');
			$(this).addClass('active');
		});
	}

	function closeMenu() {
		var btn = $('.js-close-menu'),
			menu = $('.header__formobile');
		btn.on('click',function () {
			menu.removeClass('opened');
		});
	}

	function mobMenu() {
		var btn = $('.js-menu'),
			menu = $('.header__formobile');
		btn.on('click',function () {
			menu.addClass('opened');
		});
	}

	faces();
	closeMenu();
	mobMenu();

	/* - - - - - - -        init select2       - - - - - - - - */
	$('.js-select').select2({
		language: 'ru',
		width: '100%',
		minimumResultsForSearch: -1
	});

	$('.js-select-xs').select2({
		language: 'ru',
		width: '100%',
		minimumResultsForSearch: -1,
		containerCssClass: 'select-xs',
		dropdownCssClass: 'select-xs-dropdown'
	});

	/* - - - - - - -        init inputmask       - - - - - - - - */
	$('[type="tel"]').inputmask('+7 (999) 999-99-99');

	/* - - - - - - -        init fancybox       - - - - - - - - */
	$('[data-fancybox]').fancybox({
		closeExisting: true
	});

	/* - - - - - - -        init tooltipster       - - - - - - - - */
	$('.js-tooltip').tooltipster({
		side: 'right',
		delay: 100
	});

	/* - - - - - - -        init autocomplete       - - - - - - - - */
	if(typeof(countries) !== 'undefined'){
		$('.js-autocomplete').autocomplete({
			minChars: 3,
			lookup: countries,
			onSelect: function(item) {
				$('.header__city-title').text(item.value);
				$('.js-autocomplete').val('');
				$.fancybox.close();
			}
		});
	};
	$(document).on('click', '.city-example__list span', function () {
		$('.header__city-title').text($(this).text());
		$.fancybox.close();
	});

	/* - - - - - - -        head slider       - - - - - - - - */
	$('.js-head-slider').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		dots: true,
		swipeToSlide: true,
		speed: 1000,
		fade: true,
		autoplay: true,
		autoplaySpeed: 4000,
		appendDots: $('.head-slider__dots'),
		prevArrow: $('.head-slider__arrow-prev'),
		nextArrow: $('.head-slider__arrow-next'),
	}).on('beforeChange', function(slick, currentSlide){
		if($('.js-head-slider .slick-current').next().hasClass('bg-yellow')){
			$('.head-slider__action').addClass('action-white');
		} else {
			$('.head-slider__action').removeClass('action-white');
		}
	});

	/* - - - - - - -        toggle stock       - - - - - - - - */
	$(document).on('click', '.main-stock__tabs li', function () {
		const $this = $(this);
		if(!$this.hasClass('active')){
			$('.main-stock__tabs li').removeClass('active');
			$this.addClass('active');
			$('.main-stock__box').removeClass('active');
			$('.main-stock__box[data-tab="' + $this.attr('data-tab') + '"]').addClass('active');
			if($(window).width() <= 991){
				$('html, body').animate({'scrollTop': $('.main-stock__list').offset().top - 30}, 600);
			}
		}
	});

	/* - - - - - - -        toggle tariff       - - - - - - - - */
	$(document).on('change', '.tariff-list__box [type="checkbox"]', function () {
		const isCheck = $(this).prop('checked'),
			$parent = $(this).closest('.tariff-list');
		if (isCheck) {
			$parent.find('.tariff-list__box [type="checkbox"]').prop('checked', false);
			$(this).prop('checked', true);
		} else {
			$(this).prop('checked', false);
		}
	});

	/* - - - - - - -        toggle tariff desc       - - - - - - - - */
	$(document).on('click', '.tariff-desc__box-accordion-head', function () {
		const $parent = $(this).closest('.tariff-desc__box-accordion');
		if (!$parent.hasClass('show')) {
			$parent.addClass('show');
			$parent.find('.tariff-desc__box-accordion-body').slideDown(300);
		} else {
			$parent.removeClass('show');
			$parent.find('.tariff-desc__box-accordion-body').slideUp(300);
		}
	});
	if($('.tariff-desc__box-accordion').length){
		$('.tariff-desc__box-accordion').each(function () {
			const $parent = $(this);
			if ($parent.hasClass('show')) {
				$parent.addClass('show');
				$parent.find('.tariff-desc__box-accordion-body').slideDown(300);
			} else {
				$parent.removeClass('show');
				$parent.find('.tariff-desc__box-accordion-body').slideUp(300);
			}
		});
	}

	/* - - - - - - -        toggle tariff menu       - - - - - - - - */
	$(document).on('click', '.tariff-result__menu li', function () {
		const $this = $(this);
		if(!$this.hasClass('active')){
			$('.tariff-result__menu li').removeClass('active');
			$this.addClass('active');
			$('.tariff-result__box').removeClass('show');
			$('.tariff-result__box[data-tab="' + $this.attr('data-tab') + '"]').addClass('show');
			if($(window).width() <= 1199){
				$('html, body').animate({'scrollTop': $('.tariff-result__box[data-tab="' + $this.attr('data-tab') + '"]').offset().top - 30}, 600);
			}
		}
	});
	if($('.tariff-result__menu').length){
		const $this = $('.tariff-result__menu li.active');
		$('.tariff-result__menu li').removeClass('active');
		$this.addClass('active');
		$('.tariff-result__box').removeClass('show');
		$('.tariff-result__box[data-tab="' + $this.attr('data-tab') + '"]').addClass('show');
	}

	/* - - - - - - -        toggle tariff package       - - - - - - - - */
	$(document).on('change', '.tariff-package [type="checkbox"]', function () {
		const isCheck = $(this).prop('checked'),
			$parent = $(this).closest('.tariff-result__box-grid');
		if (isCheck) {
			$parent.find('.tariff-package [type="checkbox"]').prop('checked', false);
			$(this).prop('checked', true);
		} else {
			$(this).prop('checked', false);
		}
	});

	/* - - - - - - -        toggle tariff line       - - - - - - - - */
	$(document).on('change', '.tariff-line [type="checkbox"]', function () {
		const isCheck = $(this).prop('checked'),
			$parent = $(this).closest('.tariff-result__box-list');
		if (isCheck) {
			$parent.find('.tariff-line [type="checkbox"]').prop('checked', false);
			$(this).prop('checked', true);
		} else {
			$(this).prop('checked', false);
		}
	});

	/* - - - - - - -        scrollTo       - - - - - - - - */
	$(document).on('click', '.js-scrollto', function (e) {
		e.preventDefault();
		const link = $(this).attr('href');
		$('html, body').animate({'scrollTop': $(link).offset().top - 0}, 600);
	});

	$('#tariff-range').ionRangeSlider({
		grid: true,
		hide_min_max: true,
		hide_from_to: true,
		values: [ 1024, 2048, 4096, 6144 ],
		onChange: function (data) {
			console.log(data.from_value)
		}
	});
});
